import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ToastService} from './toast.service';
import {ToastElem} from '../models/ToastElem';

@Injectable()
export class ToastInterceptor implements HttpInterceptor {

  constructor(private toastService: ToastService) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(catchError((err: HttpErrorResponse) => {
      if (request.url.split('/').pop() !== 'authenticate') {
        this.toastService.send(new ToastElem(err.message, 'danger'));
      }
      throw err;
    }));
  }
}
