import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthError, AuthService} from 'mik-auth';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  formGroup = new FormGroup({
    email: new FormControl('', [Validators.email, Validators.required]),
    password: new FormControl('', [Validators.required])
  });
  private redirect: string | null = null;

  constructor(private auth: AuthService, private router: Router, private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params.hasOwnProperty('redirect')) {
        this.redirect = params.redirect;
      }
    });
  }

  login(): void {
    if (this.formGroup.valid) {
      this.auth.logIn({
        email: this.formGroup.controls.email.value,
        password: this.formGroup.controls.password.value
      }).subscribe(() => {
        const redirect = this.redirect ? this.redirect : '';
        this.redirect = null;
        this.router.navigateByUrl(redirect);
      }, error => {
        console.error(error);
        if (error instanceof AuthError) {
          this.formGroup.setErrors({form: `Hibás e-mail/jelszó`});
        }
      });
    }
  }
}
