import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProgressBarService {
  private progressCount = 0;

  constructor() {
  }

  get Visible(): boolean {
    return this.progressCount !== 0;
  }

  increment(): void {
    this.progressCount++;
  }

  decrement(): void {
    this.progressCount = this.progressCount === 0 ? 0 : (this.progressCount - 1);
  }
}
