<main class="form-signin">
  <form (ngSubmit)="login()" [formGroup]="formGroup">
    <h1 class="h3 mb-3 fw-normal">Bejelentkezés</h1>

    <div class="form-floating">
      <input [class.is-invalid]="formGroup.controls.email.dirty && formGroup.controls.email.invalid"
             [formControlName]="'email'" class="form-control" id="floatingInput"
             placeholder="name@example.com" type="email">
      <label for="floatingInput">Email</label>
    </div>
    <div class="form-floating">
      <input [class.is-invalid]="formGroup.controls.password.dirty && formGroup.controls.password.invalid"
             [formControlName]="'password'" class="form-control" id="floatingPassword"
             placeholder="Password" type="password">
      <label for="floatingPassword">Jelszó</label>
    </div>

    <div [class.d-flex]="formGroup.hasError('form') && !this.formGroup.pristine" class="invalid-feedback">
      {{formGroup?.errors?.form}}
    </div>

    <button [disabled]="formGroup.invalid" class="w-100 btn btn-lg btn-primary mt-3" type="submit">Bejelentkezés
    </button>
  </form>
</main>
