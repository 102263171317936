import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LoginComponent} from './login/login.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {CustomAuthModule, User} from 'mik-auth';
import {environment} from '../environments/environment';
import {ReactiveFormsModule} from '@angular/forms';
import {ToastInterceptor} from './toast.interceptor';
import {ToastService} from './toast.service';
import {ProgressBarInterceptor} from './progress-bar.interceptor';
import {ProgressBarService} from './progress-bar.service';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    CustomAuthModule.forRoot({
      config: {
        apiPrefix: environment.apiPrefix,
        loginEndpoint: 'authenticate',
        userModel: User
      }
    }),
    ReactiveFormsModule
  ],
  providers: [
    ToastService,
    ProgressBarService,
    {provide: HTTP_INTERCEPTORS, useClass: ToastInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ProgressBarInterceptor, multi: true},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
